import { styled } from "@mui/material";

export const FormulaInput = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "0.75rem",
	fontWeight: "500",
	color: "#7482A0",
	height: "200px",
	border: "solid 1px #A9A9A9",
	padding: "10px",
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	minHeight: "325px",
	minWidth: "800px",

	[theme.breakpoints.up("xs")]: {
		height: "325px",
		width: "900px",
	},

	[theme.breakpoints.up("sm")]: {
		height: "325px",
		width: "900px",
	},

	[theme.breakpoints.up("md")]: {
		height: "325px",
		width: "900px",

	},
	[theme.breakpoints.up("lg")]: {
		height: "325px",
		width: "1000px",
	},
	[theme.breakpoints.up("xl")]: {
		height: "325px",
		width: "1500px",
	}
}));

export const FormulaItem = styled("div")(({ theme, borderColor }) => ({
	backgroundColor: "#FFFFFF",
	border: `2px solid ${borderColor}`,
	borderRadius: "5px",
	color: "#111111",
	flexGrow: "1",
	fontSize: "1rem",
	fontWeight: "400",
	margin: "5px",
	padding: "5px",
	textTransform: "none",
	position: "relative",
	overflow: "hidden",
	"& svg": {
		color: "#FF0000",
	},
	"&:hover": {
		backgroundColor: "#F2F2F2",
		"& svg": {
			transition: "visibility 0.2s ease-in-out",
			visibility: "visible",

			"&:hover": {
				color: "#8B0000",
			}
		}
	}
}));

export const AdvancedTriggerFormulaItem = styled("div")(({ theme }) => ({
	border: "2px solid transparent",
	borderRadius: "5px",
	backgroundImage: "linear-gradient(#FFFFFF, #FFFFFF), radial-gradient(circle at top left, #01D5AB, #0072AE)",
	backgroundOrigin: "border-box",
	backgroundClip: "padding-box, border-box",
	color: "#111111",
	flexGrow: "1",
	fontSize: "1rem",
	fontWeight: "400",
	margin: "5px",
	padding: "5px",
	textTransform: "none",
	position: "relative",
	overflow: "hidden",
	"& svg": {
		color: "#FF0000",
	},
	"&:hover": {
		backgroundImage: "linear-gradient(#F2F2F2, #F2F2F2), radial-gradient(circle at top left, #01D5AB, #0072AE)",
	}
}));

export const TrashInput = styled("div")(({ theme }) => ({
	borderRadius: "13px",
	height: "50px",
	padding: "25px",
}));