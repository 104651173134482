import { Tooltip } from "@mui/material";

import { AdvancedTriggerFormulaItem, FormulaItem } from "../styled/formula-card.styled";

export const FormulaItemWrapper = ({ borderColor, item, triggerName, isAdvancedTrigger }) => {
	if (isAdvancedTrigger) {
		return (
			<Tooltip followCursor title={
				isAdvancedTrigger ?
					<div style={{ padding: "0px", display: "inline-block" }} >
						<p>Name: {item.friendlyName}</p>
						<p>Description: {item.description}</p>
					</div>
					:
					null
			}
			>
				{
					<AdvancedTriggerFormulaItem>
						{triggerName}
						<div style={{
							position: "absolute",
							right: "10px",
							top: "50%",
							transform: "translateY(-50%)",
							visibility: "hidden",
							transition: "visibility 0.2s ease-in-out"
						}}>
						</div>
					</AdvancedTriggerFormulaItem>
				}
			</Tooltip>
		);
	} else {
		return (
			<Tooltip followCursor title={
				item.rulefield && !isAdvancedTrigger ?
					<div style={{ padding: "0px", display: "inline-block" }}>
						<p>Location: {item.rulelocation.name}</p>
						<p>Field Name: {item.rulefield.name}</p>
						<p>Field Type: {item.rulefield.fieldType}</p>
						{item.which ? <p>Which: {item.which}</p> : ""}
						{item.when ? <p>When: {item.when}</p> : ""}
					</div>
					:
					null
			}
			>
				<FormulaItem borderColor={borderColor}>
					{triggerName}
					<div style={{
						position: "absolute",
						right: "10px",
						top: "50%",
						transform: "translateY(-50%)",
						visibility: "hidden",
						transition: "visibility 0.2s ease-in-out"
					}}>
					</div>
				</FormulaItem>
			</Tooltip>
		);
	}
};