const apiRoutes = {
	// dev env
	getPackageVersion: "/api/test/packageVersion",

	// groups
	addRulesToGroup: (groupId) => `/api/v1/groups/${groupId}/rules`,
	addPluginsToGroup: (groupId) => `/api/v1/groups/${groupId}/plugins`,
	bulkDeleteGroups: "/api/v1/groups/bulk",
	bulkRemoveIdpGroupsFromGroup: (groupId) => `/api/v1/groups/${groupId}/idpgroups`,
	bulkRemoveRulesFromGroup: (groupId) => `/api/v1/groups/${groupId}/rules`,
	bulkRemoveUsersFromGroup: (groupId) => `/api/v1/groups/${groupId}/users`,
	copyGroup: (groupId) => `/api/v1/groups/${groupId}/copy`,
	createGroup: "/api/v1/groups",
	deleteGroup: (groupId) => `/api/v1/groups/${groupId}`,
	getGroupIdpGroups: (groupId) => `/api/v1/groups/${groupId}/idpGroups`,
	getGroupById: (groupId) => `/api/v1/groups/${groupId}`,
	getGroupByName: "/api/v1/groups/group",
	getGroups: "/api/v1/groups",
	getDummyGroups: "/api/v1/test/testGetDummyGroups",
	getGroupPlugins: (groupId) => `/api/v1/groups/${groupId}/plugins`,
	removeIdpGroupFromGroup: (groupId, idpGroupId) => `/api/v1/groups/${groupId}/idpgroups/${idpGroupId}`,
	removeRuleFromGroup: (groupId, ruleId) => `/api/v1/groups/${groupId}/rule/${ruleId}`,
	updateGroup: (groupId) => `/api/v1/groups/${groupId}`,
	updateGroupIdpGroups: (groupId) => `/api/v1/groups/${groupId}/idpgroups`,
	updateGroupPlugin: (groupId, pluginId) => `/api/v1/groups/${groupId}/plugin/${pluginId}`,

	//organizations
	getAdvancedTriggers: (orgId) => `/api/v1/organizations/${orgId}/advancedTriggers`,
	getOrganization: (orgId) => `/api/v1/organizations/${orgId}`,
	getOrganizationWithSamlMetadata: (orgId) => `/api/v1/organizations/${orgId}/saml`,
	login: "/api/v1/login",
	refresh: "/api/v1/refresh",
	forceLogin: (orgId) => `/api/v1/organizations/${orgId}/forceLogin`,
	killSwitch: (orgId) => `/api/v1/organizations/${orgId}/killSwitch`,
	getExternalGroups: (orgId) => `/api/v1/organizations/${orgId}/externalGroups`,
	getExternalGroupMembers: (orgId, externalGroupId) => `/api/v1/organizations/${orgId}/externalGroups/${externalGroupId}/members`,
	setSamlAppMetadata: (orgId) => `/api/v1/organizations/${orgId}/saml`,
	ssoAndScim: (orgId) => `/api/v1/organizations/${orgId}/ssoAndScim`,
	sync: (orgId) => `/api/v1/organizations/${orgId}/sync`,
	syncByTenantId: "/api/v1/organizations/syncByTenantId",
	validateRoleMappings: "/api/v1/organizations/scim/validate",

	// permissions
	getPermissions: "/api/v1/permissions",

	// plugins
	addGroupsToPlugin: (pluginId) => `/api/v1/plugins/${pluginId}/groups`,
	bulkRemoveGroupsFromPlugin: (pluginId) => `/api/v1/plugins/${pluginId}/groups`,
	getPlugins: (orgId) => `/api/v1/organizations/${orgId}/platforms`,
	getGroupsForPlugin: (pluginId) => `/api/v1/plugins/${pluginId}/groups`,
	removeGroupFromPlugin: (pluginId, groupId) => `/api/v1/plugins/${pluginId}/groups/${groupId}`,
	togglePluginStatusForOrganization: (orgId, pluginId) => `/api/v1/organizations/${orgId}/plugins/${pluginId}/toggle`,

	// requests
	getRequest: "/api/v1/requests",
	requestUpdate: "/api/v1/requests",

	// roles
	addUsersToRole: (roleId) => `/api/v1/roles/${roleId}/users/add`,
	bulkDeleteRoles: "/api/v1/roles/bulk",
	bulkRemoveIdpGroupsFromRole: (roleId) => `/api/v1/roles/${roleId}/idpgroups`,
	bulkRemoveUsersFromRole: (roleId) => `/api/v1/roles/${roleId}/users`,
	copyRole: (roleId) => `/api/v1/roles/${roleId}/copy`,
	createRole: "/api/v1/roles",
	deleteRole: "/api/v1/roles",
	getRoles: "/api/v1/roles",
	getRoleById: (roleId) => `/api/v1/roles/${roleId}`,
	getRoleIdpGroups: (roleId) => `/api/v1/roles/${roleId}/idpGroups`,
	getRolePermissions: (roleId) => `/api/v1/roles/${roleId}/permissions`,
	removeIdpGroupFromRole: (roleId, idpGroupId) => `/api/v1/roles/${roleId}/idpgroups/${idpGroupId}`,
	removeUserFromRole: (roleId, userId) => `/api/v1/roles/${roleId}/users/${userId}/remove`,
	updateRole: (roleId) => `/api/v1/roles/${roleId}`,
	updateRoleIdpGroups: (roleId) => `/api/v1/roles/${roleId}/idpGroups`,
	updateRolePermission: (roleId, permissionId) => `/api/v1/roles /${roleId}/permissions/${permissionId}`,

	// swagger
	getSwagger: "/api/v1/admin/swagger",

	// tags
	createTag: "/api/v1/tags",
	getTags: "/api/v1/tags",
	getPossibleParentTags: "/api/v1/tags/getPossibleParentTags",
	deleteTag: "/api/v1/tags",
	updateTag: "/api/v1/tags",

	// users
	addUsersToGroup: "/api/v1/users/addUsersToGroup",
	bulkDeleteUsers: "/api/v1/users/bulk",
	updateUser: "/api/v1/users",
	createUser: "/api/v1/users",
	deleteUser: "/api/v1/users",
	getUser: (isSelf, id, email, tenantId) => `/api/v1/users/user?isSelf=${isSelf}&id=${id}&email=${email}&tenantId=${tenantId}`,
	getUsers: "/api/v1/users",
	setUserActive: (userId) => `/api/v1/users/${userId}/active`,
	setUserRole: (userId) => `/api/v1/users/${userId}/setUserRole`,
	removeUserFromGroup: (userId, groupId) => `/api/v1/users/${userId}/group/${groupId} `,

	// rules
	bulkApplyRuleChanges: "/api/v1/rules/bulk",
	bulkDeleteRules: "/api/v1/rules/bulk",
	bulkPermanentDeleteRules: "/api/v1/rules/bulk",
	bulkRestoreRules: "/api/v1/rules/bulk/restore",
	bulkUpdateRuleStatus: "/api/v1/rules/bulk",
	createRule: "/api/v1/rules",
	copyRule: (ruleId) => `/api/v1/rules/${ruleId}/copy`,
	deleteRule: "/api/v1/rules",
	getRule: (ruleId) => `/api/v1/rules/${ruleId}`,
	getRules: "/api/v1/rules",
	getRuleByName: (ruleId) => `/api/v1/rules/${ruleId}`,
	updateRule: (ruleId) => `/api/v1/rules/${ruleId}`,
	toggleRuleStatus: (ruleId) => `/api/v1/rules/${ruleId}/toggleStatus`,
	restoreRule: "/api/v1/rules/rule/restore",

	// rule fields
	ruleFields: "/api/v1/rulefields",
	ruleField: "/api/v1/ruleFields",

	// rule locations
	getRuleLocations: "/api/v1/rulelocations/admin",
	getRuleLocationsForWizard: "/api/v1/rulelocations",
	getRuleLocation: "/api/v1/rulelocations",
	createRuleLocation: "/api/v1/rulelocations",
	updateRuleLocation: "/api/v1/rulelocations",

	// workstations
	getWorkstations: "/api/v1/workstations",
	bulkWorkstationKillSwitchToggle: "/api/v1/workstations/bulk/killSwitch",
	workstationKillSwitch: (uuid) => `/api/v1/workstations/${uuid}/killSwitch`,
};

export default apiRoutes;