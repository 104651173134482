const messages = {
	BULK_APPLY_FAILED_TOAST_MSG: "Bulk apply failed, please try again later.",
	BULK_APPLY_SUCCESS_TOAST_MSG: "Bulk apply was successful.",
	BULK_APPLY_MODAL_MESSAGE: (resource, targetResource) => `${resource} you add will be added to all selected ${targetResource}. ${resource} you remove will be removed from all selected ${targetResource}. ${resource} left unchanged will remain as they currently are.`,
	BULK_PERMANENTLY_DELETE_MSG: (resource) => `Are you sure you would like to permanently delete the selected ${resource} within your organization? This action is irreversible.`,
	BULK_STATUS_UPDATE_FAILED_TOAST_MSG: "Bulk status update failed, please try again later.",
	BULK_SOFT_DELETE_FAILED_TOAST_MSG: "Bulk soft deletion failed.",
	BULK_RESTORE_FAILED_TOAST_MSG: "Failed to bulk restore selected rules, please try again later.",
	CONTACT_ADMIN_MSG: "Please contact your organization's administrator for assistance.",
	DISABLE_SSO_AND_SCIM_MSG: "Disabling SSO and SCIM will remove all syncing between your provider and CoApp. All states will be frozen to their current state.",
	EMAIL_ALREADY_EXISTS_ERROR_MSG: "This email is already taken.",
	EMAIL_OR_PASSWORD_INCORRECT_ERROR_MSG: "Email and/or password incorrect, please try again.",
	WORKSTATION_KILLSWITCH_MSG: (workstationCount, isDisable = false) => `This will ${isDisable ? "re-enable" : "disable"} the browser extension on the selected workstation${workstationCount > 1 ? "s" : ""}. Are you sure you wish to proceed?`,
	FIELD_IS_REQUIRED_ERROR_MSG: "This field is required.",
	FORMULA_MISCONFIGURATION_MSG: "Incomplete trigger/formula configurations have been detected that invalidate this rule formula, please select an option below to proceed.",
	GROUP_NAME_ALREADY_EXISTS_ERROR_MSG: "A group with this name already exists in your organization.",
	GROUP_NAME_UPDATED_SUCCESS_MSG: "Group name was updated successfully.",
	GROUP_NAME_UPDATE_ERROR_MSG: "Error during group update occured, please try again later.",
	GROUP_ADD_TO_PLUGIN_SUCCESS_MSG: (num, pluginName) => `Group${num > 1 ? "s" : ""} ${num > 1 ? "were" : "was"} added to the ${pluginName} plugin successfully.`,
	GROUP_ADD_TO_PLUGIN_ERROR_MSG: (num, pluginName) => `Error while adding group${num > 1 ? "s" : ""} to the ${pluginName} plugin, please try again later.`,
	GROUP_CREATED_SUCCESS_MSG: "Group was created successfully.",
	GROUP_ADDITION_SUCCESS_MSG: "Plugins were added to the group successfully.",
	GROUP_ADDITION_ERROR_MSG: "Error during plugin addition occured, please try again later.",
	GROUP_CREATION_ERROR_MSG: "Error during group creation occured, please try again later.",
	GROUP_DELETION_ERROR_MSG: "Error during group deletion occured, please try again later.",
	GROUP_DELETED_SUCCESS_MSG: (isPlural) => `${isPlural ? "Groups were" : "Group was"} deleted successfully.`,
	GROUP_REMOVAL_SUCCESS_MSG: (isPlural) => `${isPlural ? "Groups were" : "Group was"} removed successfully.`,
	GROUP_REMOVAL_ERROR_MSG: "Error during group removal occured, please try again later.",
	GROUP_PLUGIN_TOGGLE_SUCCESS_MSG: "Plugin status was toggled successfully.",
	GROUP_PLUGIN_TOGGLE_ERROR_MSG: "Error during plugin status toggle occured, please try again later.",
	IDP_GROUPS_ADDITION_SUCCESS_MSG: (identityProvider) => `${identityProvider} groups were added successfully.`,
	IDP_GROUPS_ADDITION_ERROR_MSG: (identityProvider) => `Error during ${identityProvider} group addition occured, please try again later.`,
	IDP_GROUP_REMOVAL_SUCCESS_MSG: (identityProvider, isPlural) => `${identityProvider} ${isPlural ? "groups were" : "group was"} removed successfully.`,
	IDP_GROUP_REMOVAL_ERROR_MSG: (identityProvider) => `Error during ${identityProvider} group removal occured, please try again later.`,
	IDP_GROUP_REMOVAL_MODAL_MSG: (identityProvider, resourceName, removedResourceType) => `This will permanently remove the ${identityProvider} groups from the "${resourceName}" ${removedResourceType} in your organization. This action cannot be reversed.`,
	INVALID_EMAIL_ERROR_MSG: "Email address is invalid.",
	INVALID_PHONE_ERROR_MSG: "Phone number is invalid.",
	KILLSWITCH_ENABLED_MSG: "The CoApp browser extension is currently disabled across your entire organization. ",
	KILLSWITCH_DISABLED_MSG: "This disables the CoApp browser extension across your entire organization.",
	LOCATION_LARGE_PREVIEW_TOOLTIP: "Click to view a larger preview of this location.",
	ORGANIZTION_WIDE_FORCE_LOGIN_ACTIVATE_MSG: "Users will no longer be forced to be logged into CoApp while using AcuityLogic.",
	ORGANIZTION_WIDE_FORCE_LOGIN_DEACTIVATE_MSG: "Users will be forced to log into CoApp before they can use AcuityLogic.",
	ORGANIZATION_WIDE_FORCE_LOGIN_TOGGLE_ERROR_MSG: "Error during organization-wide force login toggle occured, please try again later.",
	ORGANIZATION_WIDE_KILL_SWITCH_TOGGLE_ERROR_MSG: "Error during organization-wide kill switch toggle occured, please try again later.",
	PAGE_ACCESS_DENIED_MSG: "Page can't be accessed.",
	PAGE_DOES_NOT_EXIST_MSG: "Whoops, this page doesn't exist.",
	PAGE_PERMISSION_DENIED_MSG: "You do not have permission to access this page.",
	PERMISSION_ADDED_ERROR_MSG: "Permission could not be added, please try again later.",
	PERMISSION_ADDED_SUCCESS_MSG: "Permission was added succesfully",
	PERMISSION_REMOVED_ERROR_MSG: "Permission could not be removed, please try again later.",
	PERMISSION_REMOVED_SUCCESS_MSG: "Permission was removed succesfully",
	PLUGIN_REMOVED_SUCCESS_MSG: "Plugin was removed successfully.",
	PLUGIN_REMOVED_ERROR_MSG: "Error during plugin removal occured, please try again later.",
	PLUGIN_DISABLED_SUCCESS_MSG: "Plugin was disabled successfully.",
	PLUGIN_DISABLED_ERROR_MSG: "Error during plugin disablement occured, please try again later.",
	PLUGIN_ENABLED_SUCCESS_MSG: "Plugin was enabled successfully.",
	PLUGIN_ENABLED_ERROR_MSG: "Error during plugin enablement occured, please try again later.",
	PLUGIN_TOGGLE_CONFIRMATION_MSG: (state, pluginName) => `Are you sure you want to ${state ? "disable" : "enable"} the ${pluginName} plugin for your organization?`,
	REMOVE_MANAGE_USERS_ROLE_MSG: (roleName) => `Removing "Manage Users, Groups, and Roles" from ${roleName} will remove your ability to managing permissions. You will not be able to turn this permission back on.`,
	RESOURCE_ALREADY_EXISTS_ERROR_MSG: (resource) => `${resource} with this name already exists.`,
	RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG: (resource) => `${resource} name already exists.`,
	ROLE_CREATED_SUCCESS_MSG: "Role was created successfully.",
	ROLE_CREATION_ERROR_MSG: "Error during role creation occured, please try again later.",
	ROLE_DELETION_CONFIRMATION_MSG: "Are you sure you want to delete this role?",
	ROLE_DELETION_ERROR_MSG: "Error during role deletion, please try again later.",
	ROLE_DELETION_SUCCESS_MSG: (isPlural) => `${isPlural ? "Roles were" : "Role was"} deleted successfully.`,
	ROLE_DUPLICATION_CONFIRMATION_MSG: "Are you sure you want to duplicate this role?",
	ROLE_DUPLICATION_ERROR_MSG: "Error while duplicating role, please try again later.",
	ROLE_DUPLICATION_SUCCESS_MSG: "Role was duplicated successfully",
	ROLE_NAME_UPDATED_MSG: "Role was updated successfully.",
	ROLE_NAME_UPDATE_ERROR_MSG: "Error updating role. Please try again later.",
	RULE_ADDED_SUCCESS_MSG: "Rule was added successfully.",
	RULE_ADDED_ERROR_MSG: "Error during rule addition occured, please try again later.",
	RULE_REMOVED_SUCCESS_MSG: (isPlural) => `${isPlural ? "Rules were" : "rule was"} removed successfully.`,
	RULE_REMOVED_ERROR_MSG: "Error during plugin removal occured, please try again later.",
	SSO_CONFIGURED_SUCCESS_TOAST_MSG: "Single sign-on (SSO) was configured successfully.",
	SSO_CONFIGURED_ERROR_TOAST_MSG: "Error occurred while configuring single sign-on (SSO), please try again later.",
	SCIM_ROLE_WARNING_TOOLTIP: "The Super Admin role must be mapped to at least one Azure group that contains one or more users before proceeding.",
	SCIM_ROLE_WARNING: "You are not in any Azure groups assigned to any CoApp roles. When you finish SSO + SCIM configuration and it syncs, you will lose access to CoApp.",
	SCIM_ROLE_SUPER_ADMIN_WARNING: (roles) => `You are not in any Azure groups being mapped to the Super Admin role in CoApp. If you proceed, you will have the following roles: ${roles.join(", ")}.`,
	SCIM_SETUP_MESSAGE_ONE: "Enabling System for Cross-domain Identity Management (SCIM) removes the ability to manually configure users in CoApp. Instead, user management must occur exclusively through groups in your Identity Provider (IdP).",
	SCIM_SETUP_MESSAGE_TWO: "Existing groups or roles that are not mapped to an IdP group will remain. However, all existing users assigned to those groups will be unassociated once you complete SCIM setup.",
	SCIM_SETUP_MESSAGE_THREE: "Any users that are in CoApp, but don't come over with a CoApp group or role assignment from your IdP, will be deleted.",
	SCIM_SETUP_MESSAGE_FOUR: "After setting up SCIM, users may be required to reauthenticate.",
	SCIM_SETUP_MESSAGE_FIVE: "User and group propagation from your IdP may take time.",
	SCIM_CONFIRM_SETUP_MSG: (identityProviderName) => `Review CoApp mappings to ${identityProviderName} before finishing setup. Users with unmapped CoApp roles will be removed from the roles. Users within unmapped CoApp groups will be removed from the groups.`,
	TAG_DESELECTION_TOOLTIP_INFO: "Hold \"CTRL\" while clicking the tag icon to filter on multiple tags and to deselect your selected tag(s) one at a time.",
	TAG_DESELECTION_TOOLTIP_ON_HOVER: "Trying to deselect this tag? Hold \"CTRL\" while clicking the tag icon to deselect.",
	TOGGLE_DRAFT_RULE_ERROR_MSG: "You cannot activate a rule that is in a draft state, please finish configuring this rule before taking this action.",
	TOO_MANY_CHARACTERS_ERROR_MSG: (limit, overage) => `Character limit is ${limit}, you are over by ${overage} character(s).`,
	TRIGGER_CONFIGURATION_TOOLTIP: "Select a location row to define variables for that page. A light blue highlighted row is currently selected for trigger configuration. Rows highlighted in dark blue have at least one trigger configured.",
	TOGGLE_ORGANIZATION_WIDE_KILLSWITCH_MSG: (state) => `This will ${state} the browser extension across your entire organization. Are you sure you wish to proceed?`,
	TRIGGER_NO_LOCATION_SELECTED_ERROR_MSG: "Please select a location to configure triggers for.",
	USER_CREATION_ERROR_MSG: "Error during user creation occured, please try again later.",
	USER_CREATION_SUCCESS_MSG: "User was created successfully.",
	USER_DELETION_SUCCESS_MSG: (isPlural) => `${isPlural ? "Users were" : "User was"} deleted successfully.`,
	USER_DELETION_ERROR_MSG: "Error during user deletion occured, please try again later.",
	USER_REMOVED_SUCCESS_MSG: (isPlural) => `${isPlural ? "Users were" : "User was"} removed successfully.`,
	USER_REMOVED_ERROR_MSG: "Error during user removal occured, please try again later.",
	USER_UPDATE_SUCCESS_MSG: "User was updated successfully.",
	USER_UPDATE_ERROR_MSG: "Error during user update occured, please try again later.",
	USERS_ADDED_SUCCESS_MSG: "Users were added successfully.",
	USERS_ADDED_ERROR_MSG: "Error during user addition occured, please try again later.",
	USER_TOGGLE_CONFIRMATION_MSG: (state, name) => `Are you sure you want to ${state === "false" ? "disable" : "enable"} ${name}?`,
	USER_TOGGLE_SUCCESS_MSG: "User status was toggled successfully.",
	USER_TOGGLE_ERROR_MSG: "Error during user status toggle occured, please try again later.",
	RULE_ACTIVATION_ERROR_MSG: "Error activating rule, please try again later.",
	RULE_ACTIVATION_SUCCESS_MSG: "Rule was activated successfully.",
	RULE_DEACTIVATION_ERROR_MSG: "Error disabling rule, please try again later.",
	RULE_DEACTIVATION_SUCCESS_MSG: "Rule was deactivated successfully.",
	RULE_CHANGES_DETECTED_MSG: "Changes have been detected on this step, please select an option below to proceed.",
	RULE_COPY_CONFIRMATION_MSG: "Are you sure you want to copy this rule?",
	RULE_COPY_ERROR_MSG: "Error copying rule, please try again later.",
	RULE_COPY_SUCCESS_MSG: "Rule was copied successfully.",
	PERM_DELETE_RULE_MSG: (isPlural) => `${isPlural ? "Rules were" : "Rule was"} permanently deleted successfully.`,
	PERM_DELETE_RULE_ERROR_MSG: "Error during rule deletion occured, please try again later.",
	RESTORE_RULE_MSG: "Rule was restored from the trash.",
	SEND_RULE_TO_TRASH_TOAST_MSG: (isPlural) => `${isPlural ? "Rules were" : "Rule was"} sent to the trash.`,
	DELETION_CONFIRMATION_MSG: (resource, resourceType) => `This will permanently delete the "${resource}" ${resourceType} from your organization. This action cannot be reversed.`,
	REMOVAL_CONFIRMATION_MSG: (resource, removedResource, resourceType, removedResourceType) => `This will permanently remove the "${removedResource}" ${removedResourceType} from the "${resource}" ${resourceType} in your organization. This action cannot be reversed.`,
	RULE_CREATED_SUCCESS_MSG: "Rule was created successfully.",
	RULE_CREATION_ERROR_MSG: "Error during rule creation occured, please try again later.",
	RULE_DELETION_ERROR_MSG: "Error during rule deletion occured, please try again later.",
	RULE_DELETION_SUCCESS_MSG: "Rule was deleted successfully.",
	RULE_FORMULA_REQUIRED_ERROR_MSG: "A formula cannot be empty and must contain one valid trigger or advanced trigger, please update your formula contents accordingly to proceed.",
	RULE_INVALID_FORMULA_ERROR: "Your formula is invalid, please review and fix the issue before proceeding.",
	RULE_LOCATION_REQUIRED_ERROR_MSG: "A location must be selected to proceed to reaction setup.",
	RULE_METADATA_ERROR_MSG: "The name and/or description of your rule are invalid, please resolve before proceeding.",
	RULE_NAME_UNIQUE_ERROR_MSG: "A rule with this name exists for your organization, rule names must be unique.",
	RULE_PARENTHESES_ERROR: (openings, closings) => `Your formula has more ${openings.length > closings.length ? "opening than closing parentheses" : "closing than opening parentheses"}, please review your formula.`,
	RULE_REACTION_REQUIRED_ERROR_MSG: "At least one reaction must be configured for the selected location to proceed.",
	RULE_RESTORE_ERROR_MSG: "Error during rule restoration occured, please try again later.",
	RULE_RESTORE_SUCCESS_MSG: "Rule was restored successfully.",
	RULE_STATE_TOOLTIP: "Green shadow indicates the rule is in an active state, yellow indicates the rule is inactive, and red indicates that the rule is a draft.",
	RULE_TRASHED_SUCCESS_MSG: "Rule was sent to trash successfully.",
	RULE_TRASHED_ERROR_MSG: "Error occurred when sending rule to the trash, please try again later.",
	RULE_UPDATE_SUCCESS_MSG: "Rule was updated successfully.",
	RULE_UPDATE_ERROR_MSG: "Error during rule update occured, please try again later.",
	RULE_TRIGGER_REQUIRED_ERROR_MSG: "At least one trigger must be configured for this rule.",
	SSO_AND_SCIM_CONFIGURED_MSG: (idpName) => `Your organization is currently using single sign-on (SSO) and SCIM for automatic user management, synced with ${idpName}. Syncing takes place automatically every 60 minutes.`,
	SSO_AND_SCIM_CONFIGURED_SUCCESS_TOAST_MSG: "Syncing successfully iniated.",
	SSO_AND_SCIM_CONFIGURED_ERROR_TOAST_MSG: "Error occurred while initiating syncing, please try again later.",
	SSO_AND_SCIM_NOT_CONFIGURED_MSG: "This setup wizard will take you through single sign-on (SSO) and Cross-domain Identity Managment (SCIM) integration with your identity provider.",
	SSO_AND_SCIM_DISABLED_SUCCESS_TOAST_MSG: "SSO and SCIM were disabled successfully.",
	SSO_AND_SCIM_DISABLED_ERROR_TOAST_MSG: "Error occurred while disabling SSO and SCIM, please try again later.",
	SYNC_ORGANIZATION_STARTED_TOAST_MSG: "Organization sync has started.",
	SYNC_ORGANIZATION_FAILED_TO_START_TOAST_MSG: "Organization sync failed to start.",
	UNDO_FAILED_MSG: (action) => `Failed undoing ${action}.`,
	WORKSTATION_STATUS_TOGGLE_SUCCESS_MSG: "Workstation status was toggled successfully.",
	WORKSTATION_STATUS_TOGGLE_ERROR_MSG: "Error during workstation status toggle occured, please try again later.",
	WORKSTATION_TOGGLE_CONFIRMATION_MSG: (state, name) => `Are you sure you want to ${state === "false" ? "disable" : "enable"} ${name}?`,
};

export default messages;